<template>
  <layout-page :page-description="pageDescription">
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          small
          text
          @click="saveRelation()"
          :loading="dataContext.loading"
          >
          <v-icon>save</v-icon> {{translate('Save')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <v-layout row wrap v-if="dataContext.loading">
      <v-flex md6>
        <v-skeleton-loader type="table" />
      </v-flex>
      <v-flex md6>
        <v-skeleton-loader type="table" />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="equal-height-cards" v-if="!dataContext.loading">
      <v-flex md6>
        <v-card>
          <v-card-text>
            <collection-table
              :loading="dataContext.loading"
              :items="dataContext.relation.targets"
              :schema="dataContext.relation.targetCollectionSchema"
              :sortable="false"
              :draggable="true"
              >
              <template v-slot:delete-item="{item, items}">
                <confirm-button>
                  <template v-slot:button="confirm">
                    <v-btn text small icon color="warning" @click="confirm">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:confirm="cancel">
                    <v-item-group multiple>
                      <v-btn text small color="error" @click="unreferenceItem({item, items: dataContext.relation.targets})">
                        <v-icon>delete</v-icon> {{translate('Unreference this item?', 'remove.relation.item')}}
                      </v-btn>
                      <v-btn text small icon @click="cancel">
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </v-item-group>
                  </template>
                </confirm-button>
              </template>
              <template v-slot:actions-prepend="">
                <v-layout>
                  <v-btn color="primary" @click="saveRelation(relation.targets)">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-layout>
              </template>
            </collection-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md6>
        <v-card>
          <v-card-text>
            <collection-table
              :loading="dataContext.loading"
              :items="dataContext.relatee.items"
              :schema="dataContext.relatee.schema"
              :channels="dataContext.relatee.channels"
              :relations="dataContext.relatee.relations"
              :reverse-relations="dataContext.relatee.reverseRelations"
              :searchable="true"
              :sortable="true"
              >
              <template v-slot:edit-item="{item}">
                <td style="width: 1px">
                  <v-btn
                    small
                    icon
                    text
                    color="primary"
                    @click="referenceItem({item, items: dataContext.relation.targets})">
                    <v-icon>add</v-icon>
                  </v-btn>
                </td>
              </template>
              <template v-slot:actions-prepend="">
                <v-layout>
                  <v-btn color="primary" @click="saveRelation(relation.targets)">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-layout>
              </template>
            </collection-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </layout-page>
</template>
<script>
import axios from 'axios'
import LayoutPage from '@/components/layout/layout-page.vue'
import {mapGetters} from 'vuex'
import CollectionTable from '@/components/collections/collection-table.vue'
import ConfirmButton from '@/components/confirm-button.vue'

export default {
  props: ['relationId', 'sourceKey'],
  components: {
    LayoutPage,
    CollectionTable,
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'relationsConfig', 'collectionsConfig', 'translateRelationName', 'translate', 'createPageDescription']),
    pageDescription () {
      let {sourceCollectionId, targetCollectionId} = this.dataContext.relation
      return sourceCollectionId && targetCollectionId && this.createPageDescription()
        .addCollection({collectionId: sourceCollectionId})
        .addCollection({collectionId: targetCollectionId})
        .addCollectionItem({collectionId: sourceCollectionId, itemKey: this.sourceKey})
    }
  },
  asyncComputed: {
    dataContext: {
      async get () {
        let relation = await this.api.relations.getRelation({relationId: this.relationId, sourceKey: this.sourceKey})
        let relateeAttributes = this.collectionsConfig.getTableAttributesForLoading(relation.targetCollectionId)
        let relatee = await this.api.collections.getCollection({collectionId: relation.targetCollectionId, query: {
          attributes: relateeAttributes && relateeAttributes.join(',')
        }})
        return {
          loading: false,
          relation, relatee
        }
      },
      default: {
        loading: true,
        relation: {
          targets: [],
          targetCollectionSchema: {}
        },
        relatee: {
          items: [],
          schema: {},
          channels: {},
          relations: {},
          reverseRelations: {}
        }
      }
    }
  },
  methods: {
    async loadData () {
      let {data: relation} = await axios.get(`/api/v1/relation/${this.relationId}/${this.sourceKey}`)
      let {data: targetCollection} = await axios.get(`/api/v1/collection/${relation.targetCollectionId}`)
      return {
        data: {
          relation,
          targetCollection
        }
      }
    },
    referenceItem ({item, items}) {
      if (!items.find(({key}) => key === item.key)) {
        items.push(item)
      }
    },
    unreferenceItem ({item, items}) {
      // const index = items.findIndex(existing => existing === item)
      const index = items.indexOf(item)
      if (index >= 0) {
        items.splice(index, 1)
      }
    },
    saveRelation () {
      this.api.relations.updateRelation({
        update: [{
          relationId: this.relationId,
          sourceKey: this.sourceKey,
          targets: this.dataContext.relation.targets.map(({key, properties}) => ({key, properties}))
        }]
      })
      // TODO: Modify something to trigger invalidate of async property dateContext
    },
  }
}
</script>
